import { DISCONNECT_ACTION } from "./AccountReducer";

export const GET_RER_SETTINGS_ACTION = "GET_RER_SETTINGS_ACTION";
export const GET_RER_SETTING_LIST = "GET_RER_SETTING_LIST";
export const DELETE_RER_PACKAGE_NAME = "DELETE_RER_PACKAGE_NAME";
export const ADD_RER_PACKAGE_NAME = "ADD_RER_PACKAGE_NAME";
export const UPDATE_RER_PACKAGE_NAME = "UPDATE_RER_PACKAGE_NAME";

const rerReducer = function (
  state = {
    settings: [],
    deviceModels: [],
    osVersions: [],
  },
  action
) {
  const { type, payload } = action;

  switch (type) {
    case DISCONNECT_ACTION:
      return (state = {
        settings: [],
        deviceModels: [],
        osVersions: [],
      });
    case GET_RER_SETTINGS_ACTION:
      return {
        ...state,
        settings: payload.map((s) => {
          if (s["rer_nb_received_during_last_hour"] == null) {
            s["rer_nb_received_during_last_hour"] = 0;
          }
          if (s["rer_max_nb_per_hour"] == null) {
            s["rer_max_nb_per_hour"] = 5000; // default to 5,000 RER POST per hour
          }
          s["rer_limit_reached"] =
            s["rer_nb_received_during_last_hour"] >= s["rer_max_nb_per_hour"];
          return s;
        }),
      };
    case GET_RER_SETTING_LIST:
      const deviceModels = payload.deviceModels;
      deviceModels.sort(sortAscending);
      const osVersions = payload.osVersions;
      osVersions.sort(sortAscending);
      return { ...state, deviceModels, osVersions };
    case DELETE_RER_PACKAGE_NAME:
      return {
        ...state,
        settings: state.settings.filter((s) => s["package"] !== payload),
      };
    case ADD_RER_PACKAGE_NAME:
      return { ...state, settings: [...state.settings, payload] };
    case UPDATE_RER_PACKAGE_NAME:
      return {
        ...state,
        settings: [
          ...state.settings.map((s) => {
            if (s.package === payload.packageName) {
              s[payload.key] = payload.value;
            }
            return s;
          }),
        ],
      };
    default:
      return state;
  }
};

const sortAscending = (a, b) => {
  const itemA = a.toLowerCase();
  const itemB = b.toLowerCase();
  if (itemA < itemB) {
    return -1;
  } else if (itemA > itemB) {
    return 1;
  } else {
    return 0;
  }
};

export default rerReducer;
