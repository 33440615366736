import { combineReducers } from "redux";
import rerReducer from "./RERReducer";
import psrReducer from "./PSRReducer";
import accountReducer from "./AccountReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  RER: rerReducer,
  PSR: psrReducer,
});

export default rootReducer;
