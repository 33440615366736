import React from "react";
import DotLoader from "components/loaders/DotLoader";
import styles from "./LoadingPage.module.css";

function LoadingPage() {
  return (
    <div className={styles.pageContainer}>
      <DotLoader />
    </div>
  );
}

export default LoadingPage;
