import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "router/router";
import { persistor, store } from "redux/setupStore";
import DotLoader from "components/loaders/DotLoader";
import './App.css';


function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={<DotLoader />} persistor={persistor}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
      </PersistGate>
    </Provider>


  );
}

export default App;
