import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { disconnectAction } from "redux/actions/accountActions";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "hoc/Layout";
import { store } from "redux/setupStore";

import LoadingPage from "pages/LoadingPage";

const RERSettingsDetailPage = lazy(() =>
  import("pages/rer/RERSettingsDetailPage")
);
const LoginPage = lazy(() => import("pages/LoginPage"));
const HomePage = lazy(() => import("pages/HomePage"));
const PSRHomePage = lazy(() => import("pages/psr/PSRHomePage"));
const RERHomePage = lazy(() => import("pages/rer/RERHomePage"));

function Router({ accessToken, _disconnectAction }) {
  useEffect(() => {
    axios.interceptors.request.use(function (config) {
      const accessToken = store.getState().account.accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    });

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // disconnect user
        if (error.response.status === 401) {
          toast.error("Session expired");
          _disconnectAction();
        }
        return Promise.reject(error);
      }
    );
  }, [_disconnectAction]);

  if (!accessToken) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route path={`/login`} exact component={LoginPage} />
          <Redirect from="/" to={`/login`} />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Layout>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route
            path={`/settings/:packageName`}
            exact
            component={RERSettingsDetailPage}
          />
          <Route path={`/psr`} exact component={PSRHomePage} />
          <Route path={`/psr/:menu_item`} exact component={PSRHomePage} />
          <Route path={`/rer`} exact component={RERHomePage} />
          <Route path={`/rer/:menu_item`} exact component={RERHomePage} />
          <Route path={`/`} exact component={HomePage} />
          <Redirect from="/" to={`/`} />
        </Switch>
      </Suspense>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.account.accessToken,
});

const mapDispatchToProps = {
  _disconnectAction: disconnectAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
