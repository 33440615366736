/* eslint-disable no-undef */

import { useState, useEffect } from "react";
import Settings from "Settings";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
    verySmallScreen: window.innerWidth < Settings.screenSize.verySmallScreen,
    smallScreen: window.innerWidth < Settings.screenSize.smallScreen,
    mediumScreen: window.innerWidth < Settings.screenSize.mediumScreen,
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}
