/* eslint-disable no-undef */
import { useState, useEffect } from "react";

export default function useScroll() {
  const [scroll, setScroll] = useState({
    x: document.body.getBoundingClientRect().left,
    y: -document.body.getBoundingClientRect().top,
    distanceFromBottom:
      getHeight() -
      (-document.body.getBoundingClientRect().top + window.innerHeight),
    direction: "",
  });

  function getHeight() {
    var body = document.body,
      html = document.documentElement;
    return Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  }

  // eslint-disable-next-line no-unused-vars
  const listener = (e) => {
    setScroll((prev) => ({
      x: document.body.getBoundingClientRect().left,
      y: -document.body.getBoundingClientRect().top,
      distanceFromBottom:
        getHeight() -
        (-document.body.getBoundingClientRect().top + window.innerHeight),
      direction:
        prev.y > -document.body.getBoundingClientRect().top ? "up" : "down",
    }));
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
    // eslint-disable-next-line
  }, []);

  return scroll;
}
