import React from "react";
import styles from "./DotLoader.module.css";

function DotLoader() {
  return (
    <div className={styles.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default DotLoader;
