import axios from "axios";
import Settings from "Settings";
import toast from "react-hot-toast";

import {
  GET_ACCOUNT_ACTION,
  DISCONNECT_ACTION,
  GET_FTP_CONFIG_ACTION,
  UPDATE_CONFIG_ACTION,
} from "redux/reducers/AccountReducer";

export const setAccountAction = ({ username, accessToken }) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_ACTION,
      payload: { username, accessToken },
    });
  };
};

export const disconnectAction = () => {
  return async (dispatch) => {
    dispatch({
      type: DISCONNECT_ACTION,
    });
  };
};

export const getFTPConfigAction = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${Settings.API_BASE_URL}/config`);
      dispatch({
        type: GET_FTP_CONFIG_ACTION,
        payload: response.data,
      });
    } catch (e) {
      if (e.response.status !== 401) {
        toast.error("Error while trying to reach backend");
      }
    }
  };
};

export const updateFTPConfigAction = (body) => {
  return async (dispatch) => {
    try {
      await axios.put(`${Settings.API_BASE_URL}/config`, body);
      dispatch({
        type: UPDATE_CONFIG_ACTION,
        payload: body,
      });
      toast.success("Configuration update successfully");
    } catch (e) {
      if (e.response.status !== 401) {
        toast.error("Error while trying to reach backend");
      }
    }
  };
};
