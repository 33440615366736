export const GET_ACCOUNT_ACTION = "GET_ACCOUNT_ACTION";
export const DISCONNECT_ACTION = "DISCONNECT_ACTION";
export const GET_FTP_CONFIG_ACTION = "GET_FTP_CONFIG_ACTION";
export const UPDATE_CONFIG_ACTION = "UPDATE_CONFIG_ACTION";

const accountReducer = function (
  state = {
    accessToken: undefined,
    username: undefined,
    ftpConfig: undefined,
  },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case GET_ACCOUNT_ACTION:
      return { ...state, ...payload };
    case DISCONNECT_ACTION:
      return {
        accessToken: undefined,
        username: undefined,
        config: undefined,
      };
    case GET_FTP_CONFIG_ACTION:
      return {
        ...state,
        config: _convertConfig(payload),
      };
    case UPDATE_CONFIG_ACTION:
      return {
        ...state,
        config: state.config
          ? { ...state.config, ..._convertConfig(payload) }
          : payload,
      };
    default:
      return state;
  }
};

function _convertConfig(payload) {
  const ftpConfig = {};
  const ftpHost = payload["ftp_host"];
  const ftpPemKeySecretId = payload["ftp_pem_key_secret_id"];
  const ftpPasswordSecretId = payload["ftp_password_secret_id"];
  const ftpUploadEnabled = payload["ftp_upload_enabled"];
  const ftpUsername = payload["ftp_username"];
  const emailExtractEnabled = payload["email_extract_enabled"];
  const email = payload["email"];
  const supersetUsername = payload["superset_username"];
  const mdaSdkCustomer = payload["mda_sdk_customer"];
  const ftpUploadPath = payload["ftp_upload_path"];

  if (ftpHost !== undefined) ftpConfig["ftpHost"] = ftpHost;
  if (ftpPemKeySecretId !== undefined)
    ftpConfig["ftpPemKeySecretId"] = ftpPemKeySecretId;
  if (ftpPasswordSecretId !== undefined)
    ftpConfig["ftpPasswordSecretId"] = ftpPasswordSecretId;
  if (ftpUploadEnabled !== undefined)
    ftpConfig["ftpUploadEnabled"] = ftpUploadEnabled;
  if (ftpUsername !== undefined) ftpConfig["ftpUsername"] = ftpUsername;
  if (emailExtractEnabled !== undefined)
    ftpConfig["emailExtractEnabled"] = emailExtractEnabled;
  if (email !== undefined) ftpConfig["email"] = email;
  if (supersetUsername !== undefined)
    ftpConfig["supersetUsername"] = supersetUsername;
  if (mdaSdkCustomer !== undefined)
    ftpConfig["mdaSdkCustomer"] = mdaSdkCustomer;
  if (ftpUploadPath !== undefined) ftpConfig["ftpUploadPath"] = ftpUploadPath;

  return ftpConfig;
}

export default accountReducer;
