class Settings {
    // static API_BASE_URL = 'http://localhost:5000'
    static API_BASE_URL = 'https://3mkdxsmwp5.execute-api.eu-west-1.amazonaws.com/rerDashboard';
    static WEB_SITE_BASE_URL = 'https://customer.vo-player-qox.com';
    static screenSize = {
        verySmallScreen: 500,
        smallScreen: 700,
        mediumScreen: 1500
      };

    static RER_STATE = {
      RER_ENABLED: 'RER_ENABLED',
      RER_ENABLED_WITH_TIMERANGE: 'RER_ENABLED_WITH_TIMERANGE',
      RER_DISABLED: 'RER_DISABLED'
    }

    static rerStateColors = {
        RER_ENABLED: 'rgb(165, 214, 167)',
        RER_ENABLED_WITH_TIMERANGE: 'rgb(255, 204, 128)',
        RER_DISABLED: 'rgb(239, 154, 154)'
      }

    static rerStateText = {
        RER_ENABLED: 'RER enabled',
        RER_ENABLED_WITH_TIMERANGE: 'RER enabled with time range',
        RER_DISABLED: 'RER disabled'
      }

    static getStatus(timeRanges) {
      if (timeRanges.length === 0) {
        return Settings.RER_STATE.RER_ENABLED
      }
      const currentTimestamp = Date.now()
      console.log(timeRanges, currentTimestamp)
      for (let i = 0; i < timeRanges.length; i++) {
        if (currentTimestamp >= timeRanges[i]['start_date'] && currentTimestamp <= timeRanges[i]['end_date']) {
          return Settings.RER_STATE.RER_ENABLED_WITH_TIMERANGE
        }
      }
      return Settings.RER_STATE.RER_DISABLED
    }
}

export default Settings;