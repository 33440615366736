import React, { useEffect, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import useWindowSize from "hooks/useWindowSize";
import useScroll from "hooks/useScroll";
import styles from "./Layout.module.css";

const TopMenu = lazy(() => import("components/menus/TopMenu"));
// const TopMenuSmallScreens = lazy(() =>
//   import("components/menus/TopMenuSmallScreens")
// );

// const DefaultFooter = lazy(() => import("components/footer/DefaultFooter"));

function FloatingBottomBar({ scroll }) {
  if (scroll.y > 200) {
    return <div className={styles.bottomInfos}>VO QoX Customer Dashboard</div>;
  } else {
    return null;
  }
}

FloatingBottomBar.propTypes = {
  scroll: PropTypes.object.isRequired,
};

function Layout({ history, children }) {
  const windowSize = useWindowSize();
  const scroll = useScroll();

  useEffect(() => {
    // reset scroll to top when arriving on a new page
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return (
    <div className={styles.layoutContainer}>
      {history.location.pathname !== `${"BUILD_FOLDER_NAME"}/profiles` && (
        <>
          {/* {windowSize.smallScreen ? (
            <Suspense fallback={<></>}>
              <TopMenuSmallScreens onLogout={onLogout} history={history} />
            </Suspense>
          ) : ( */}
          <Suspense fallback={<div className={styles.menuSpace}></div>}>
            <TopMenu history={history} />
          </Suspense>
          {/* )} */}
        </>
      )}
      <div
        className={
          history.location.pathname === `${"BUILD_FOLDER_NAME"}/playback`
            ? styles.childrenContainerNoMargin
            : windowSize.smallScreen
            ? styles.defaultChildrenContainer
            : styles.childrenContainer
        }
      >
        {children}
      </div>
      {/* {history.location.pathname !== `${'BUILD_FOLDER_NAME'}/playback` &&
        history.location.pathname !== `${'BUILD_FOLDER_NAME'}/profiles` && (
          <Suspense fallback={<></>}>
            <DefaultFooter />
          </Suspense>
        )} */}
      <FloatingBottomBar scroll={scroll} />
      <Toaster />
    </div>
  );
}

Layout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node,
};

Layout.defaultProps = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
