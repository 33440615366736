import { DISCONNECT_ACTION } from "./AccountReducer";

export const GET_PSR_AVAILABLE_EXTRACTIONS_ACTION =
  "GET_PSR_AVAILABLE_EXTRACTIONS_ACTION";

const psrReducer = function (
  state = {
    availableExtractions: undefined,
  },
  action
) {
  const { type, payload } = action;

  switch (type) {
    case DISCONNECT_ACTION:
      return {
        availableExtractions: undefined,
      };
    case GET_PSR_AVAILABLE_EXTRACTIONS_ACTION:
      return { ...state, availableExtractions: payload };

    default:
      return state;
  }
};

export default psrReducer;
